// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/neuronexus-logo.png";
import { ROUTE_SUPPORT_CONTACT } from "routeConstants";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Radiens-AI",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/@neuronexustech",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/neuronexus-tech/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/user/TheNeuroNexus",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/company/about-us" },
        { name: "NeuroNexus", href: "https://www.neuronexus.com" },
      ],
    },
    {
      name: "help & support",
      items: [{ name: "contact us", href: ROUTE_SUPPORT_CONTACT }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Radiens-AI by{" "}
      <MKTypography
        component="a"
        href="https://www.neuronexus.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        NeuroNexus
      </MKTypography>
      .
    </MKTypography>
  ),
};
