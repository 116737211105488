/**
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
 All of the routes for the Material Kit 2 PRO React React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 6. The `component` key is used to store the component of its route.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
         a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
         you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import Pricing from "layouts/pages/company/pricing";
import Summa from "layouts/pages/radiens/summa";
import Chat from "layouts/pages/support/chat";
import ContactUsPage from "layouts/pages/support/contact-us";

// Account
import SignInBasicPage from "layouts/authentication/sign-in/basic/signInBasic";
import Account from "pages/Radiens/Account/account";

// Route constants
import SignOutButton from "./layouts/authentication/sign-in/basic/signOut";
import {
  ACCOUNT,
  CHAT,
  CONTACT_US,
  PRODUCTS,
  RADIENS,
  ROUTE_ACCOUNT,
  ROUTE_AUTHENTICATION_SIGN_IN_BASIC,
  ROUTE_PRODUCTS_CHAT,
  ROUTE_PRODUCTS_PRICING,
  ROUTE_RADIENS_SUMMA,
  ROUTE_SUPPORT_CONTACT,
  SIGN_IN,
  SUMMA,
} from "./routeConstants";
import store from "./store";

export function getRoutes() {
  if (store.getState().user.idToken) {
    return [...commonRoutes, signedInAccountRoutes];
  }
  return [...commonRoutes, signedOutAccountRoutes];
}

const commonRoutes = [
  {
    name: PRODUCTS,
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: PRODUCTS,
        route: ROUTE_PRODUCTS_PRICING,
        component: <Pricing />,
        description: "View our products and packages.",
      },
      {
        name: CHAT,
        route: ROUTE_PRODUCTS_CHAT,
        component: <Chat />,
        description: "Get a quote customized to your needs.",
      },
    ],
  },
  {
    name: CONTACT_US,
    route: ROUTE_SUPPORT_CONTACT,
    icon: <Icon>contacts</Icon>,
    component: <ContactUsPage />,
  },
  {
    name: RADIENS,
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: SUMMA,
        route: ROUTE_RADIENS_SUMMA,
        component: <Summa />,
        description: "Visualize experiment summaries",
      },
    ],
  },
];

const signedOutAccountRoutes = {
  name: ACCOUNT,
  icon: <Icon>person</Icon>,
  collapse: [
    {
      name: SIGN_IN,
      component: <SignInBasicPage />,
      route: ROUTE_AUTHENTICATION_SIGN_IN_BASIC,
    },
  ],
};

const signedInAccountRoutes = {
  name: ACCOUNT,
  icon: <Icon>person</Icon>,
  collapse: [
    {
      name: ACCOUNT,
      component: <Account />,
      route: ROUTE_ACCOUNT,
      description: "Manage your account",
    },
    {
      name: "Sign Out",
      component: <SignOutButton />,
      route: "/sign-out",
    },
  ],
};

export const allRoutes = [...commonRoutes, signedInAccountRoutes, signedOutAccountRoutes];

export default commonRoutes;
