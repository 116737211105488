import MKButton from "components/MKButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, signOutUser } from "store";

export default function SignOutButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  return (
    <MKButton
      variant="contained"
      color="primary"
      onClick={() => {
        console.log("signing out...");
        return dispatch(signOutUser()).then(() => navigate("/"));
      }}
    >
      Sign Out
    </MKButton>
  );
}
