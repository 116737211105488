/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom"; // Import useNavigate

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// Routes
import footerRoutes from "footer.routes";

import { fetchAuthSession } from "aws-amplify/auth";
import CommonNavbar from "components/CommonNavbar/CommonNavbar";
import { ROUTE_RADIENS_SUMMA } from "routeConstants";
import { RootState, setUser } from "store";

export default function SignInBasicPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { idToken } = useSelector((state: RootState) => state.user);

  if (idToken) {
    return <Navigate to={location.state?.from || ROUTE_RADIENS_SUMMA} />;
  }

  return (
    <>
      <CommonNavbar />
      <div style={{ height: "100px" }}></div>
      <Authenticator>
        {() => {
          fetchAuthSession().then((session) => {
            const userId = session?.userSub;
            const userName = session?.tokens?.idToken?.payload?.name?.toString();
            const idToken = session?.tokens?.idToken?.toString();
            if (idToken && userId) {
              dispatch(setUser({ userId, userName, idToken }));
            }
          });
          return <Navigate to={location.state?.from || ROUTE_RADIENS_SUMMA} />;
        }}
      </Authenticator>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
