// filepath: /Users/DillonKipke/dev/sapiens/website-nnx/src/routeConstants.ts

export const PRODUCTS = "products";
export const CHAT = "chat";
export const CONTACT_US = "contact us";
export const RADIENS = "radiens";
export const SUMMA = "summa";
export const SIGN_IN = "sign-in";
export const ACCOUNT = "account";

export const ROUTE_PRODUCTS_PRICING = "/products/pricing";
export const ROUTE_PRODUCTS_CHAT = "/products/chat";
export const ROUTE_SUPPORT_CONTACT = "/support/contact-us";
export const ROUTE_RADIENS_SUMMA = "/radiens/summa";
export const ROUTE_AUTHENTICATION_SIGN_IN_BASIC = "/authentication/sign-in/basic";
export const ROUTE_ACCOUNT = "/account";
