/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Pricing page sections
import Header from "pages/Company/Pricing/components/Header";
import PricingSection from "pages/Company/Pricing/sections/Pricing";

// Routes
import footerRoutes from "footer.routes";

import headerBg from "assets/images/bg-bright-neurons.jpeg";
import CommonNavbar from "components/CommonNavbar/CommonNavbar";
import ComparisonTable from "./sections/ComparisonTable";

function Pricing() {
  return (
    <>
      <CommonNavbar />
      <Header
        image={headerBg}
        label="Products"
        title="Choose the package that works for you"
        description="Radiens-AI consists of three applications, plus optional MATLAB and Python APIs for advanced customization. Every part is fast, secure, and cloud-enabled."
        cards={[
          {
            variant: "contained",
            color: "info",
            icon: "fast_forward",
            title: "Videre",
            description: (
              <>
                <strong>File-based</strong> analysis and visualization of Ephys big-data sets via an
                intuitive UI. Import your existing data and let Videre do the rest.
              </>
            ),
          },
          {
            variant: "contained",
            color: "info",
            icon: "radio_button_checked",
            title: "Allego",
            description: (
              <>
                <strong>Real-time</strong> analysis and visualization of neural recordings, plus DAQ
                hardware controls and an innovative digital model of the neural interface.
              </>
            ),
          },
          {
            variant: "contained",
            color: "info",
            // icon: "auto_awesome",
            icon: "auto_fix_high",
            title: "Curate",
            description: (
              <>Curation of Ephys big data sets via an intuitive UI, included with every package.</>
            ),
          },
        ]}
      />
      <MKBox p={3}>
        <PricingSection />
      </MKBox>
      <MKBox p={3}>
        <ComparisonTable />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pricing;
