/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// @mui material components
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Coworking page component
import CommonNavbar from "components/CommonNavbar/CommonNavbar";
import ContactOption from "./ContactOption";

function ContactUs() {
  return (
    <>
      <CommonNavbar />
      <Grid container spacing={3} alignItems="center">
        <Grid item ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }}>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox component="section" py={{ xs: 3, md: 12 }}>
              <Container>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="h3" my={1}>
                      Contact us
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                      Our offices are open from 9:00AM to 5:00PM (Eastern Time Zone), Monday through
                      Friday.
                      <br />
                      <br />
                      <strong>NeuroNexus Headquarters</strong>
                      <br />
                      5200 S. State Rd. Suite 200
                      <br />
                      Ann Arbor, MI 48108
                      <br /> United States of America
                    </MKTypography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
                  >
                    <Stack>
                      <ContactOption
                        icon="phone"
                        href="tel:+1 734 913 8858"
                        content="+1 734 913 8858"
                      />
                      <ContactOption
                        icon="phone"
                        href="tel:+1 734 786 0069"
                        content="+1 734 786 0069"
                      />
                      <ContactOption
                        icon="email"
                        href="mailto:sales@neuronexus.com"
                        content="sales@neuronexus.com"
                      />
                      <ContactOption
                        icon="support_agent"
                        href="mailto:support@neuronexus.com"
                        content="support@neuronexus.com"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
