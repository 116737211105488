import { configureStore, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signOut } from "aws-amplify/auth";
import * as process from "process";

const BASE_URL = process.env["REACT_APP_BASE_URL"];

// Define the state type
export interface UserSession {
  userId: string | undefined;
  userName: string | undefined;
  idToken: string | undefined;
}

interface Plan {
  name: string;
  is_active: boolean;
  no_of_users: number;
  created_at: string;
  subsCount: string | null;
}

interface UserSubscription {
  allowed_user: string;
  name: string;
  user_id: string;
  role: string;
  email: string;
  created_at: string;
  is_active: boolean;
  no_of_users: number;
  address: string | null;
  organization_id: string;
  plan: string[];
  total_quantity: string;
  seat: number;
  stripe_id: string;
  organization_name: string;
  plans: Plan[];
}

interface SummaData {
  columns: string[];
  rows: string[][];
}

// Define initial state
const initialUserState: UserSession = {
  userId: undefined,
  userName: undefined,
  idToken: undefined,
};

export const signOutUser = createAsyncThunk("signOut", async () => {
  return signOut();
});

// Create a slice
const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<UserSession>) => {
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
      state.idToken = action.payload.idToken;
    },
  },
  extraReducers(builder) {
    builder.addCase(signOutUser.fulfilled, (state) => {
      state.userId = undefined;
      state.userName = undefined;
      state.idToken = undefined;
    });
  },
});

const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.idToken;
      if (token) {
        headers.set("Authorization", token);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserSubscription: builder.query<UserSubscription, string>({
      query: (userId) => `user/${userId}`,
    }),
    getSummaData: builder.query<SummaData, string>({
      query: (userId) => `summaKpis/user/${userId}`,
    }),
  }),
});

export const { useGetUserSubscriptionQuery, useGetSummaDataQuery } = api;

// Export actions
export const { setUser } = userSlice.actions;

// Create the Redux store
const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

// Define RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
