/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Routes
import footerRoutes from "footer.routes";

import CommonNavbar from "components/CommonNavbar/CommonNavbar";
import Plotly from "plotly.js-dist-min";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState, useGetSummaDataQuery } from "../../../store";
import { ROUTE_AUTHENTICATION_SIGN_IN_BASIC } from "../../../routeConstants";
import { useLocation, useNavigate } from "react-router-dom";

// const data = [
//   {
//     z: [
//       [1, null, 30, 50, 1],
//       [20, 1, 60, 80, 30],
//       [30, 60, 1, -10, 20],
//     ],
//     x: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
//     y: ["Morning", "Afternoon", "Evening"],
//     type: "bar",
//     hoverongaps: false,
//   },
// ];

function Summa() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: summaData } = useGetSummaDataQuery(user?.userId || "");

  useEffect(() => {
    if (!user.userName) {
      navigate(ROUTE_AUTHENTICATION_SIGN_IN_BASIC, { state: { from: location } });
    }
  }, [user, navigate, location]);

  const plots = useMemo(() => {
    if (!summaData) {
      return undefined;
    }

    const datasets = summaData?.rows.map((r) => r[1]);
    const snr = summaData?.rows.map((r) => parseFloat(r[2]));
    const events = summaData?.rows.map((r) => parseFloat(r[3]));

    const snrData = {
      x: datasets,
      y: snr,
      name: "SNR",
      type: "bar",
    };

    const eventData = {
      x: datasets,
      y: events,
      name: "Event Rate",
      type: "bar",
    };

    const data = [snrData, eventData];
    return data;
  }, [summaData]);

  useEffect(() => {
    plots?.forEach((p) =>
      // @ts-ignore
      Plotly.newPlot(`plotDiv-${p.name}`, [p], { title: p.name, autosize: true, width: 700 })
    );
  }, [plots]);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <CommonNavbar />
      </MKBox>
      <Grid container spacing={3} alignItems="center" width="100%">
        <Grid item ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }} width="90%">
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
            width="100%"
          >
            <MKBox component="section" py={{ xs: 3, md: 12 }} width="100%">
              <Container>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={5} width="100%">
                    <MKTypography variant="h3" my={1}>
                      Summa
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                      Summary of lab datasets
                    </MKTypography>
                    {plots?.map((p) => {
                      return (
                        <Grid item xs={12} lg={5} key={p.name}>
                          <div id={`plotDiv-${p.name}`} style={{ width: "100%" }}></div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Container>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Summa;
