/**
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as ReactDOMClient from "react-dom/client";
import { Amplify } from "aws-amplify";
import App from "App";
import store, { setUser } from "store";
import { fetchAuthSession } from "aws-amplify/auth";

Amplify.configure({
  Auth: {
    Cognito: {
      // @ts-ignore
      region: "us-east-1",
      userPoolId: "us-east-1_QSNdyQpm2",
      userPoolClientId: "1q4ugfpgijro91l1cjdhau60cq",
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
    },
  },
});

fetchAuthSession().then((session) => {
  const userId = session?.userSub;
  const userName = session?.tokens?.idToken?.payload?.name?.toString();
  const idToken = session?.tokens?.idToken?.toString();
  if (idToken && userId) {
    store.dispatch(setUser({ userId, userName, idToken }));
  }
});

const container = document.getElementById("root");
if (container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(<App />);
} else {
  throw new Error("Failed to find the root element");
}
