import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { ROUTE_PRODUCTS_CHAT } from "routeConstants";
import { getRoutes } from "routes";
import MKBox from "components/MKBox";

export default function CommonNavbar() {
  return (
    <MKBox position="fixed" top="0.5rem" width="100%">
      <DefaultNavbar
        routes={getRoutes()}
        action={{
          type: "internal",
          route: ROUTE_PRODUCTS_CHAT,
          label: "get a quote",
          color: "info",
        }}
      />
    </MKBox>
  );
}
