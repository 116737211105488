/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { Link } from "react-router-dom";
import { ROUTE_PRODUCTS_PRICING } from "routeConstants";

// Images

function Information() {
  return (
    <MKBox component="section" py={{ xs: 6, lg: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h2" mb={1}>
              Experience the speed
              <br />
              and power
            </MKTypography>
            <MKTypography variant="body2" mb={2}>
              A fast, secure, and reliable platform that can be used by everyone in your research
              group – technicians, students, post-docs, and scientists.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} textAlign="right">
            <MKButton
              variant="gradient"
              color="warning"
              component={Link}
              to={ROUTE_PRODUCTS_PRICING}
            >
              View products
            </MKButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="library_add_check"
              title="Three powerful apps in one suite"
              description="Built-in, first-class support for DAQ control, data curation, and signal analysis." //— each tailored to streamline a specific part of the research workflow, and built to seamlessly integrate with each other."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="insights"
              title="Real-time analysis across hundreds of channels"
              description="Conduct sophisticated experiments with hundreds of channels, and analyze the massive datasets efficiently."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="price_change"
              title="Save time & money"
              description="Affordably priced, high-end analytics -- customize your package to get exactly what you need."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="tune"
              title="Completely customizable"
              description="Total control over experiment protocols with built-in customizations, plus Python and MATLAB APIs, all compatible with most lab systems."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="lock"
              title="Control, secure, and share your data"
              description="Keep your data exactly where you want it and in the formats that you use regularly, including Python- and Matlab–friendly file formats."
              small
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} mb={3}>
            <DefaultInfoCard
              icon="track_changes"
              title="Software that evolves with your research"
              description="Radiens-AI is always growing and transforming with the demands of neuroscience. Let us handle the technical details so that you can focus on the science."
              small
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
