/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Table from "examples/Tables/Table";

// Material Kit 2 PRO React components

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// @mui material components

// Material Kit 2 PRO React components

// Material Kit 2 PRO React examples

import { useSelector } from "react-redux";

import "@aws-amplify/ui-react/styles.css";

// Routes
import CommonNavbar from "components/CommonNavbar/CommonNavbar";
import footerRoutes from "footer.routes";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { ROUTE_AUTHENTICATION_SIGN_IN_BASIC } from "routeConstants";
import { RootState, useGetUserSubscriptionQuery } from "store";

export default function AccountPage() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const { data: subData } = useGetUserSubscriptionQuery(user?.userId || "");

  useEffect(() => {
    if (!user.userName) {
      navigate(ROUTE_AUTHENTICATION_SIGN_IN_BASIC, { state: { from: location } });
    }
  }, [user, navigate, location]);

  return (
    <>
      <CommonNavbar />
      <div style={{ height: "100px" }}></div>
      <MKBox textAlign="center" mt={4}>
        <MKTypography variant="h3" component="h2">
          Welcome {user.userName}
        </MKTypography>
        <MKTypography variant="h5" component="h2" mt={2}>
          Your subscriptions
        </MKTypography>
        <MKBox textAlign="center" mt={4} px={2}>
          <Table
            columns={[
              { name: "name", align: "left" },
              { name: "number of seats", align: "center" },
              { name: "created date", align: "center" },
              { name: "is active", align: "center" },
            ]}
            rows={subData?.plans.map((plan) => ({
              name: plan.name,
              ["number of seats"]: plan.no_of_users,
              ["created date"]: plan.created_at,
              ["is active"]: plan.is_active ? "Yes" : "No",
            }))}
          />
        </MKBox>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
